.font_8, .font_8 * {
  font: @font_8;
}

.font_7, .font_7 * {
  font: @font_7;
}

.font_6, .font_6 * {
  font: @font_6;
}

.font_5, .font_5 * {
  font: @font_5;
}

.font_4, .font_4 * {
  font: @font_4;
}

.font_3, .font_3 * {
  font: @font_3;
}

.font_2, .font_2 * {
  font: @font_2;
}

.font_1, .font_1 * {
  font: @font_1;
}

.caps {
  text-transform: uppercase;
}

.headernames_box {

  > h1 {
    .font_2;
    margin-bottom: 10px;
  }

  > h2 {
    .font_2;
    margin-bottom: 10px;
  }

  > h3 {
    .font_3;
    margin-bottom: 10px;
  }

  > h4 {
    .font_4;
    margin-bottom: 10px;
  }

}

// TEXT BOX
.text_box {

  h1 {
    .font_2;
  }

  h2 {
    .font_2;
  }

  h3 {
    .font_3;
  }

  h4 {
    .font_4;
  }

}

.quote,
.quote_c,
blockquote {
  position: relative;
  margin-top: @gap /2;
  margin-bottom: @gap;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  background: @color_b6 + 5%;
  .radius(@br);
  padding: 20px 20px 20px 60px;
}

.quote_c {
  text-align: center;
  padding-left: 20px !important;
}

.quote,
blockquote {
  &::before {
    content: "";
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    background: @color_1;
    .radius(@br 0 0 @br);
  }

  &::after {
    content: "\f10d";
    width: 40px;
    height: 40px;
    position: absolute;
    color: @color_w;
    .fas;
    .centerxy;
    font-size: 23px;
    top: 20px;
    left: 8px;
    z-index: 2;
    display: block;

  }
}


ul:not(.todo-list){
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto @gap *2 auto;

  > li {
    position: relative;
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;
    padding-left: 25px;

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      left: 9px;
      top: 9px;
      z-index: 3;
      background: @color_2;
      .radius(100%);

    }

    > * {
      margin: 10px auto 5px;

      > li {

        &:before {
          content: '';
          width: 7px;
          height: 7px;
          position: absolute;
          left: 9px;
          top: 9px;
          z-index: 3;
          background: @color_b4;
          .radius(100%);
        }
      }
    }
  }
}
ul.todo-list{
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto @gap *2 auto;

  > li {
    position: relative;
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;
    padding-left: 30px;

    label {
      position: absolute;
      left: 0;
      top: -2px;
      margin-right: 10px;
    }
  }
}
ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto @gap *2 auto;

  > li {
    position: relative;
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;
    padding-left: 30px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color_w;
      background: @color_1;
      width: 23px;
      height: 23px;
      .centerxy;
      position: absolute;
      line-height: 17px;
      left: 0;
      top: 3px;
      font-size: 12px;
      font-weight: 600;
      .radius(@br);

    }
  }
}


// LISTS STYLE
//=============================================================================
[class*='list_v'] {
  margin: 0 auto 20px;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
  }
}

.list_v1 {
  li {

    &:before {
      content: @fa-var-check;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 0;
      .fas;
      z-index: 3;
      .centerxy;
      font-size: 17px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_2;
    }
  }
}

.list_v2 {
  li {
    &:before {
      content: "\f00d";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 3;
      .centerxy;
      font-size: 16px;
      text-rendering: auto;
      font-style: normal !important;
      color: @color_4 !important;
      .fas;
    }
  }
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;

  > li {
    list-style: none;
    margin-bottom: 15px;
    min-height: 25px;

    &::before {
      content: counter(num) ' ' !important;
      counter-increment: num !important;
      color: @color_w;
      background: @color_1;
      width: 25px;
      height: 25px;
      .centerxy;
      position: absolute;
      line-height: 17px;
      left: -8px;
      top: 0;
      font-size: 13px;
      font-weight: 600;
      .radius(@br);

    }
  }
}

.list_v4 {
  li {
    padding: 0 0 14px 0;
    border-bottom: 1px solid @color_b7;
  }
}

.list_v5 {
  > li {
    margin-bottom: 10px;

    &::before {
      content: "\f111";
      background-position: 50% -20px * 18;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -3px;
      top: 1px;
      font-size: 9px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_2;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: "\f111";
          background-position: 50% -20px * 18;
          width: 20px;
          height: 20px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}

.list_v6 {
  > li {
    margin-bottom: 8px;
    padding-left: 13px;

    &::before {
      content: @fa-var-circle;
      width: 10px;
      height: 10px;
      position: absolute;
      left: -3px;
      top: 3px;
      font-size: 6px;
      .fas;
      z-index: 3;
      .centerxy;
      color: @color_1;
    }

    > ul {

      margin: 13px auto 0;

      > li {
        width: 100%;

        &::before {
          content: @fa-var-circle;
          background-position: 50% -20px * 18;
          width: 10px;
          height: 10px;
          position: absolute;
          left: -3px;
          top: 1px;
          font-size: 9px;
          .fas;
          z-index: 3;
          .centerxy;
          color: @color_b5;
        }
      }
    }
  }
}


