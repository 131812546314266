.page-content,
.footer {
  z-index: 2;
  position: relative;
}

.backgrounds-layer__wrap {
  position: absolute;
  display: flex;
  white-space: normal;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: @color_w;

  transform-origin: center;
  transition: 1s;


  .backgrounds-layer__item {
    position: absolute;

    &.bg-layer-1 {
      width: 50%;
      right: 0;
      top: 0;
      text-align: right;

      img {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 100%;
        height: auto;
      }

    }

    &.bg-layer-2 {
      width: 40%;
      height: auto;
      left: -10%;
      top: 30%;
    }

    &.bg-layer-3 {
      width: 22%;
      height: auto;
      left: -10%;
      top: 48%;
    }

    &.bg-layer-4 {
      width: 35%;
      height: auto;
      right: -10%;
      top: 60%;
    }

    &.bg-layer-5 {
      width: 40%;
      height: auto;
      left: -10%;
      bottom: -5%;
    }

    &.bg-layer-6 {
      width: 30%;
      height: auto;
      right: -10%;
      bottom: -4%;
    }

    &.bg-layer-news-head {
      width: 50%;
      height: auto;
      right: -5%;
      top: -4%;
      transform: scaleX(-1);
    }

    &.bg-layer-news-head2 {
      width: 30%;
      height: auto;
      right: -13%;
      top: 10%;
      opacity: .6;
    }
  }
}

[class*='_title'] {
  font: @font_1;
}

[class*='_subtitle'] {
  font: @font_4;
}

@media screen and (max-width: 1124px) {
  body, html {
    font: @font_6;
  }

  [class*='_title'] {
    font: @font_2;
  }

  [class*='_subtitle'] {
    font: @font_5;
  }

}

// MAIN - WELCOME
.section__welcome-block {
  width: 40%;

  .page-content__subtitle {
    .font_4;
  }

  .page-content__title {
    .font_1;
    margin-bottom: 10px;
  }

  .page-content_text {
    .font_5;
  }

}

// MAIN - GENERAL INFO
.section__general-information {
  width: 50%;

  .more-box {
    .limitation-box(113px, 22px, 5);
  }
}

// MAIN - FACT LIST
.section__fact-list-wrap {
  .fact-list__item {
    .fact-list__icon {
      display: flex;
      justify-content: center;
      color: @color_1;
      font-size: 135px;
      text-shadow: 10px 20px 40px rgba(@color_b1, 0.04);
      margin: 40px auto;
    }

    .fact-list__text__wrap {
      .fact-list__text {
        .limitation-box(132px, 22px, 6);
      }

    }
  }

  .page-content__text {
    width: 100% !important;
    max-width: 840px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

// MAIN - Project outputs /results
.project-outputs {
  > div {
    strong {
      color: @color_4;
    }
  }

  > *:last-of-type {
    width: 100%;
    max-width: 840px;
    margin: auto;
  }
}


// PREVIEWS LIST

.preview-list {
  .preview-list__item {
    margin-right: -@gap/2;
    margin-left: -@gap/2;
    margin-bottom: @gap*2;
    display: flex;

    > * {
      padding-right: @gap/2;
      padding-left: @gap/2;
    }

    .preview-list__image {
      flex: 1 0 36%;

      .preview-list__image__wrap {
        display: flex;
        padding-bottom: 58%;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        box-shadow: 0 20px 40px -4px rgba(@color_b1, 0.2);

        img {
          position: absolute;
          -o-object-fit: cover !important;
          object-fit: cover !important;
          width: 100%;
          height: 100%;
          font-family: 'object-fit: cover;';
          .anim_on(.12s);
        }

        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }

    .preview-list__description {
      flex: 1 1 auto;

      > * {
        margin-bottom: @gap;
      }

      h1, h2 {
        font: @font_2;
      }

      h3 {
        font-weight: @f_sbold;
        color: @color_b3;
      }

      h4 {
        font: @font_4;
      }

    }

    &:nth-child(2n+2) {
      .preview-list__image {
        order: 1;
      }

      .preview-list__description {
        order: 0;
      }
    }

  }
}

@media only screen and (max-width: 680px),
only screen and (max-device-width: 680px) {
  .preview-list {
    .preview-list__item {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;

      > * {
        padding-right: 0;
        padding-left: 0;
      }

      .preview-list__image {
        .preview-list__image__wrap {
          margin-bottom: @gap;
        }
      }

      &:nth-child(2n+2) {
        .preview-list__image {
          order: 0;
        }

        .preview-list__description {
          order: 1;
        }
      }
    }
  }
}

.news-date{
  position: relative;
  top: -20px;
  font-size: 21px;
}

// ONE OBJECT
.one-object__item{
  margin-bottom: @gap *3;
}
.one-object__title{
  font-size: 34px;
  margin-bottom: 13px;
}
.one-object__subtitle{
  font-weight: @f_sbold;
  margin-bottom: 13px;
}
.one-object__main-image{
  margin-bottom: 13px;

  img{
    width: 100%;
    max-width: 100%;
  }
}

// MORE BUTTON
.btn-more {
  display: flex;
  align-items: flex-start;

  a {
    display: flex;
    width: auto;
    height: @elements_h - 4px;
    padding-right: @gap;
    padding-left: @gap;
    border: 1px solid @color_1;
    text-decoration: none;
    color: @color_b1;
    .radius(@br);
    font: @font_7;
    align-items: center;
    justify-content: center;
    justify-items: center;

    &::after {
      content: @fa-var-angle-right;
      .fas;
      text-indent: -10px;
      opacity: 0;
      .anim_on(0.2s);

    }

    &:hover {

      &::after {
        text-indent: 10px;
        opacity: .7;
      }
    }
  }

}

// SECTION ONE OBJECT
.section-content-wrap {

  h2 {
    position: relative;
    font-size: 33px;
    line-height: 110%;
    margin-bottom: 13px;
  }

  h3 {
    position: relative;
    font-size: 25px;
    font-weight: @f_sbold;
    line-height: 110%;
    margin-bottom: 13px;
  }

  h4 {
    position: relative;
    font-size: 21px;
    line-height: 110%;
    font-weight: @f_sbold;
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 13px;
  }

  .attach {
    display: flex;
    flex-wrap: wrap;
    //border: 1px solid red;
    flex-direction: column;
    margin-bottom: @gap;

    a {
      display: flex;
      flex: 1 0 @elements_h;
      align-items: center;
      padding: 10px 50px 10px 0;
      text-decoration: none;
      color: @color_b1;
      position: relative;

      &::after {
        content: @fa-var-paperclip;
        .fas;
        color: @color_b5;
        position: absolute;
        right: 10px;
      }

      &:hover {
        &::after {
          color: @color_1;
        }
      }
    }

    > * {
      //border: 1px solid red;
      border-bottom: 1px solid @color_b6;
      //background: @color_w;
      //box-shadow: 0 5px 30px -5px rgba(@color_b1, .2);
      position: relative;
      margin-bottom: 0 !important;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}

// SLIDER
.big-slider {
  position: relative;
  width: auto;
  .radius(@br);
  margin-bottom: @gap;
  overflow: hidden;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;

  p {
    margin-bottom: 0;
  }

  &::after {
    content: @fa-var-search-plus;
    font-size: 20px;
    .fas;
    color: @color_1;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    .anim_on(0.3s);
    .anim-delay(1.0s);
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    margin-right: 15px;
    margin-left: 15px;
  }

  a {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 70vh;
    padding-bottom: 66.6%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      position: absolute;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      width: 100%;
      height: 100%;
      font-family: 'object-fit: cover;';
    }
  }
}

/*
// ONE NEWS
.section__one-news {
  .one-news {
    max-width: 740px;

    h1, h2 {
      font: @font_1;
      margin-bottom: @gap /2;

    }

    h3, h4 {
      font: @font_3;
      margin-bottom: @gap /2;
      opacity: .8;
    }

    .one-news__main-image {
      margin-bottom: @gap;
      overflow: hidden;

      img {
        -o-object-fit: cover !important;
        object-fit: cover !important;
        width: 100%;
        height: 100%;
        font-family: 'object-fit: cover;';
      }
    }

    .one-news__date {
      margin-bottom: @gap;
    }

    .one-news__full-text {
      margin-bottom: @gap;

      h1, h2 {
        position: relative;
        font-size: 32px;
        padding-bottom: @gap /2;
        margin-bottom: @gap /2;
      }

      h3, h4 {
        position: relative;
        font: @font_2;
        padding-bottom: @gap /2;
        margin-bottom: @gap /2;

        &::before {
          content: '';
          display: block;
          width: 75px;
          height: 3px;
          background: @color_1;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }

      p {
        margin-bottom: @gap;
      }
    }


  }
}

// MATEERIALS
.section__project-materials {
  .resolution-wrap {
    max-width: 740px;


  }
}

// ONE PROJECT

.section__one-project {
  .one-project {
    max-width: 740px;

    .one-project__title {
      font-weight: @f_sbold;
      font-size: 32px;
      margin-bottom: @gap /2;
    }

    .one-project__sub-title {
      font: @font_3;
      margin-bottom: @gap /2;
      opacity: .8;
    }

    .one-project__main-image {
      margin-bottom: @gap;
      overflow: hidden;

      img {
        -o-object-fit: cover !important;
        object-fit: cover !important;
        width: 100%;
        height: 100%;
        font-family: 'object-fit: cover;';
      }
    }

    .one-project__date {
      margin-bottom: @gap;
    }

    .one-project__full-text {
      margin-bottom: @gap;

      h2 {
        position: relative;
        font-size: 32px;
        margin-bottom: @gap /2;
      }

      h3 {
        position: relative;
        font: @font_2;
        margin-bottom: @gap /2;
      }

      h4 {
        position: relative;
        font: @font_3;
        margin-bottom: @gap /2;
      }

      p {
        margin-bottom: @gap;
      }
    }

    .one-project__attached-files {
      display: flex;
      flex-wrap: wrap;
      //border: 1px solid red;
      flex-direction: column;

      > * {
        display: flex;
        //border: 1px solid red;

        flex: 1 0 @elements_h;
        align-items: center;
        padding: 10px 50px 10px 0;
        border-bottom: 1px solid @color_b6;
        background: @color_w;
        //box-shadow: 0 5px 30px -5px rgba(@color_b1, .2);
        text-decoration: none;
        color: @color_b1;
        position: relative;

        &::after {
          content: @fa-var-paperclip;
          .fas;
          color: @color_b5;
          position: absolute;
          right: 10px;
        }

        &:hover {
          &::after {
            color: @color_1;
          }
        }

      }
    }
  }
}
*/


// CONTACTS
.section__founders {
  .founders-block__item {
    margin-bottom: @gap * 4;

    .founders-block__image {
      img {
        width: 100%;
      }
    }

    .founders-block__content {
      > * {
        margin-bottom: 10px;
      }

      .founders-block__title {
        font: @font_2;
      }

      .founders-block__info {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          margin-bottom: 5px;

          i {
            position: relative;
            top: 2px;
            margin-right: 10px;
            color: @color_1;
          }
        }
      }

      .founders-block__description {

      }
    }


  }
}

.section__project-team {
  .project-team__item {
    margin-bottom: @gap*2;

    .project-team__subtitle {
      display: flex;
      align-items: center;
      justify-items: center;
      margin-bottom: @gap;

      > span {
        display: flex;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        overflow: hidden;

        img {
          display: block;
          width: 99%;
          height: 99%;
        }

      }

      > div {
        font: @font_2;

        > span {
          color: @color_b3;
        }
      }
    }

    .project-team__list {
      display: flex;
      margin-left: -@gap;
      margin-right: -@gap;
      flex-wrap: wrap;

      .project-team__list__item {
        padding-left: @gap;
        padding-right: @gap;
        width: 33.33%;
        margin-bottom: @gap*2;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 1124px), only screen and (max-device-width: 1124px) {
          width: 50%;
        }

        @media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
          width: 100%;
          margin-bottom: @gap;
        }

        .team-unit__box {
          .box-shadow;

          .team-unit__position {
            font: @font_7;
            display: flex;
            opacity: .8;
            margin-bottom: 5px;
            padding-bottom: 7px;
            position: relative;

            &::after {
              content: '';
              display: block;
              width: 40px;
              height: 3px;
              background: @color_1;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }

          .team-unit__name {
            font: @font_5;
            font-weight: @f_sbold;
          }

        }
      }
    }
  }
}

.section__cooperation-center {
  .cooperation-center__list {
    .cooperation-center__item {
      .cooperation-center__box {
        .box-shadow;
        background: url("/img/card-bg-1.png") @color_w 100% 100% no-repeat;
        background-size: 50% auto !important;
        align-content: start;
        text-align: left !important;

        > *:not(:last-child) {
          margin-bottom: @gap;
        }

        .cooperation-center__title {
          font: @font_2;

          > span {
            opacity: .6;
          }
        }

        .cooperation-center__address {
          padding-left: 50px;
          position: relative;
          font-weight: @f_sbold;


          &::before {
            content: @fa-var-map-marker-alt;
            position: absolute;
            left: 0;
            top: 5px;
            .fas;
            font-size: 26px;
            color: @color_1;
            margin-right: 10px;
          }
        }

        .cooperation-center__position {
          font: @font_7;
          display: flex;
          opacity: .8;
          margin-bottom: 8px;
          padding-bottom: 10px;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 40px;
            height: 3px;
            background: @color_1;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        .cooperation-center__contact {

          .cooperation-center__contact__name {
            font: @font_5;
            font-weight: @f_sbold !important;
          }

        }

      }

      &:nth-child(2) {
        .cooperation-center__box {
          background-image: url("/img/card-bg-2.png");
        }
      }

      @media only screen and (max-width: 680px), only screen and (max-device-width: 680px) {
        flex: 0 0 100%;
      }
    }
  }
}

.box-shadow {
  padding: 22px 20px;
  .radius(@br);
  background: @color_w;
  box-shadow: 0 5px 26px 0 rgba(@color_4 - 80%, .08);
}

// slick tools
button.slick-arrow {
  display: block;
  top: 50%;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: @color_1 !important;
  opacity: 0;
  .radius(@br);
  .centerxy;
  .transform(translateY(-50%));

  &:hover {
    background: @color_2;
    opacity: 1 !important;
  }

  &::before {
    position: relative;
    .fas;
  }
}

&:hover {
  button.slick-arrow {
    opacity: 0.5;
  }

}

button.slick-prev.slick-arrow {
  left: 5px;

  &::before {
    content: "\f053";
    left: -2px;
  }
}

button.slick-next.slick-arrow {
  right: 5px;

  &::before {
    content: "\f054";
    right: -2px;
  }
}

button.slick-lightbox-close {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  z-index: 8;

  &::before {
    content: @fa-var-times;
    position: relative;
    font-size: 22px;
    color: @color_1;
    .fas;
  }
}


// MAIN - LOGOS
.section__partners {
  .col-wrap__4 {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 180px;
      }
    }
  }
}


// HEADER DECORS

// ========================================

[class*='hn-decor_'] {
  padding: 20px 0;
  margin-bottom: 10px;
  position: relative;

  &::after {
    content: url("@{img}icons/icons.svg#hn-decor");
    position: absolute;
    display: block;
    width: 90px;
    height: 6px;
  }
}

[class*='_top'] {
  &::after {
    top: -44px;
  }
}

[class*='-_left'] {
  &::after {
    left: 0;
  }
}

[class*='-_center'] {
  text-align: center;

  &::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

[class*='-right'] {
  text-align: right;

  &::after {
    right: 0;
  }
}

[class*='_top'] {
  &::after {
    bottom: 0;
  }
}

.limitation-box(@max-h, @line-h, @line-clamp) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: @line-h; /* fallback */
  max-height: @max-h; /* fallback */
  -webkit-line-clamp: @line-clamp; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.more-box {
  position: relative;
  transition: 0.2s;

  .more-box__btn {
    position: absolute;
    bottom: -1px;
    right: 0;
    color: @color_4;
    background: @color_w;
    padding: 2px 5px;
    z-index: 4;

    &:hover {
      cursor: pointer;
    }
  }
}

.more-box.active {
  .limitation-box(1000px, 22px, 200) !important;
}

.snake-block__item {
  display: flex;
  align-items: stretch;
  margin-left: -@gap;
  margin-right: -@gap;

  .snake-block__item__col {
    padding: 0 @gap;

    &:first-child {
      width: 60%;
    }

    &:last-child {
      width: 40%;

      img {
        width: 100%;
        overflow: hidden;
        .radius(8px);
      }
    }
  }
}

.snake-block__item:nth-child(2n+1) {
  flex-direction: row-reverse;
}

.slick-lightbox{
  .slick-lightbox-slick-item-inner{
    width: 100%;

    img{
      width: 100%;
      max-width: 980px;
    }
  }
}
