.footer{

  .resolution-wrap{
    .footer__content{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -@gap;
      margin-right: -@gap;

      > .footer__content__item{
        padding-left: @gap;
        padding-right: @gap;

        .footer__contact-box{
          >*{
            position: relative;
            margin-bottom: 13px;

            &::before{
              content: '';
              display: flex;
              margin-right: 15px;
              font-size: 17px;
              color: @color_1;
              position: absolute;
              left: 0;
              top: 2px;
            }
          }
          .contact-box__address{
            padding-left: 22px;

            &::before{
              content: @fa-var-map-marker-alt;
              .fas;
            }
          }
          .contact-box__position{
            font: @font_7;
            display: flex;
            opacity: .8;
            margin-bottom: 8px;
            padding-bottom: 10px;
            position: relative;

            &::after {
              content: '';
              display: block;
              width: 40px;
              height: 3px;
              background: @color_1;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
          .contact-box__contact-info{
            .contact-info__name{
              font-weight: @f_sbold;
            }
            .contact-info__email{
              a{
                font-size: 16px;
              }
              &::before{
                content: @fa-var-envelope;
                color: @color_1;
                font-size: 16px;
                .fas;
              }
            }
          }
        }

        .footer__item__text{
          font: @font_7;
        }
      }

      > .footer__content__item:nth-child(1),
      > .footer__content__item:nth-child(2){
        width: 25%;
      }
      > .footer__content__item:nth-child(3){
        width: 50%;
      }

      @media screen and (max-width: 1180px){
        > .footer__content__item:nth-child(1),
        > .footer__content__item:nth-child(2),
        > .footer__content__item:nth-child(3){
          width: 33%;
        }
      }
      @media screen and (max-width: 940px){
        > .footer__content__item:nth-child(1),
        > .footer__content__item:nth-child(2){
          width: 50%;
        }
        > .footer__content__item:nth-child(3){
          width: 100%;
        }
      }
    }
  }

  .footer__copyright-wrap{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 40px;
    font: @font_7;

    .copyr-year{
      margin: 0 7px 0 2px;
    }
  }
}

.footer__hn-hr{
  margin-bottom: 10px;
  position: relative;
  font-weight: @f_sbold;

  > span{
    opacity: 0.6;
  }

}


@media screen and (max-width: 1124px) {
  .footer{
    .resolution-wrap{
      .footer__content{
        flex-direction: column;
        .footer__content__item{
          width: 100% !important;

          &:not(:last-of-type){
            margin-bottom: @gap*3;
          }
        }
      }
    }
    .footer__copyright-wrap{
      font-size: 12px;
    }
  }
}
