@media screen and (max-width: 1124px) {
  .backgrounds-layer__wrap {
    .backgrounds-layer__item {
      &.bg-layer-1 {
        display: none;
      }
    }
  }

  .resolution-wrap{
    max-width: 680px !important;
  }

  // MAIN - WELCOME
  .section__welcome-section{
    background: url("@{img}img_mobile_bg.jpg") 50% 50% no-repeat;
    background-size: cover !important;
    position: relative;

    &::before{
      content: '';
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(135deg, rgba(@color_1, 0.8), rgba(@color_3, 0.75));
      position: absolute;
      top: 0;
      left: 0;
    }

    .section__welcome-block {
      padding-top: 40px;
      padding-bottom: 40px;
      z-index: 3;
      position: relative;
      width: 100%;
      color: @color_w;
    }
  }


  // MAIN - GENERAL INFO
  .section__general-information {
    width: 100%;

    .more-box{
      .limitation-box(120px, 22px, 5);
    }
  }

  // MAIN - FACT LIST
  .col-wrap__3.section__fact-list-wrap {
    flex-direction: column;

    > .fact-list__item{
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .fact-list__icon{
        min-width: 150px;
        font-size: 80px;
        margin: 0;

      }
    }
  }

  // MAIN - Project outputs /results
  .section__results-wrap{
    .project-outputs{
      > div{
        width: 100%;
      }
      > *:last-of-type{
        width: 100%;

      }
    }
  }

  // MAIN - NEWS
  .section__news{
    .snake-block__item{
      flex-direction: column-reverse;

      .snake-block__item__col{
        width: 100%;

        &:last-child{
          margin-bottom: 20px;
        }
      }
    }
  }

  // MAIN - LOGOS
  .section__partners{
    .col-wrap__4{
      > div{
        img{
          width: 90px;
        }
      }
    }
  }

}