// ACCORDION BASE
.accordion {
  ul, li {
    width: 100%;
  }

  li,
  > li > ul,
  > li > div {
    width: 100%;
    height: auto;
  }

  > li > ul,
  > li > div {
    display: none;
  }

  > li.active {
    height: auto;
  }

  li:hover {
    //cursor: pointer;
  }
}

// accordion styles v1
.accordion_v1 {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;

  > li { // lvl 1 - acc-header + acc-body
    background: @color_b5;
    margin-bottom: 7px;

    > a, > mark { // lvl 1 - acc-header 1 lvl
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      text-align: left;

      border-left: 2px solid @color_1;
      padding: 10px 60px 10px 15px;
      font: @font_5;
      color: @color_b1;
      text-decoration: none;
      .anim_on(@speed);
      min-height: 60px;

      &:before { // lvl 1 - arrow
        content: '\f107';
        width: 40px;
        height: 40px;
        position: absolute;
        right: 10px;
        top: 50%;
        .fas;
        margin-top: -20px;
        font-size: 26px;
        z-index: 2;
        color: rgba(0, 0, 0, 0.3);
        .centerxy;
      }

      &:hover { // lvl 1 - acc_header - hover effect
        color: @color_w;
        background-color: @color_b2 - 20%;
      }
    }

    > .acc_body { // lvl 1 - acc_body
      font: @font_6;
      padding: 15px;
      background: @color_b6 + 10%;
    }

    > .acc_body > li { // lvl 2 - acc_header + acc-body
      > a, > span { // lvl 2 - acc_header
        display: block;
        width: 100%;
        height: auto;
        padding: 10px 15px 11px;
        background: @color_b5 - 8%;
        margin-bottom: 2px;
        font-size: 20px;
        .anim_on(@speed);

        &:hover { // lvl 2 - acc_header hover effect
          color: @color_w;
          background-color: @color_b2 - 30%;
        }

        &:before { // lvl 2 - acc_header arrow
          content: '\f107';
          width: 40px;
          height: 40px;
          position: absolute;
          right: 8px;
          top: 50%;
          .fas;
          margin-top: -20px;
          font-size: 26px;
          z-index: 2;
          color: rgba(0, 0, 0, 0.3);
          .centerxy;
        }
      }

      > .acc_body { // lvl 2 - acc_body
        padding: 13px 5px;
        font: @font_8;
      }
    }

    > .acc_body > li.active { // lvl 2 - active
      > a, > span { // lvl 2 - active - acc_header
        background: @color_1;
        color: @color_w !important;
        .shadow(0 15px 20px -15px rgba(0, 0, 0, 0.05));

        &:before { // lvl 2 - active - arrow
          color: @color_w;
          .transform(rotate(-180deg));
        }
      }
    }
  }

  > li.active { // lvl 1 - active
    background: @color_1;

    > a, > mark { // lvl 1 - active acc_header
      color: @color_w !important;

      &:before { // lvl 2 - active arrow
        .transform(rotate(-180deg));
        color: @color_w;
      }
    }
  }
}

// accordion style
@acc_text_color: @color_b1;
@acc_1color: @color_w;
@acc_2color: @color_b7;
@acc_arrow_n: @color_b6;
@acc_arrow_a: @color_1;

// ACCORDION v3
.accordion_v3 {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  background: @acc_1color;
  .shadow(@shadow);
  padding: 0 10px;
  .radius(@br);

  > li {

    > .acc_header {
      .centery;
      text-align: left;
      padding: 8px 40px 8px 0;
      font: @font_3;
      color: @color_b5;
      text-decoration: none;
      .anim_on(@speed);
      min-height: 60px;
      background: none;
      cursor: pointer;
      position: relative;

      &::after { // lvl 1 - arrow
        content: @fa-var-caret-down;
        position: absolute;
        right: 10px;
        top: 50%;
        .transform(translateY(-50%) scale(1));
        .fas;
        cursor: pointer;
        z-index: 0;
        font-size: 20px;
        color: @acc_arrow_n;
      }

    }

    > .acc_body {

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      &:last-child {
        margin-bottom: 9px;
      }

      > li {

        position: relative;

        &:not(:last-child) {
          margin-bottom: 3px;
        }

        &:last-child {
          margin-bottom: 9px;
        }

        > .acc_header {
          .radius(@br);
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-content: stretch;
          align-items: center;

          padding: 0 40px 0 10px;
          font: @font_4;
          color: @acc_text_color;
          background: @acc_2color;
          text-decoration: none;
          .anim_on(@speed);
          min-height: 44px;

          // .stream box css here (if need)

          > .score_box {
            min-width: 50%;
            max-width: 360px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: center;
            align-items: center;


            > .team_l,
            > .team_r,
            > .score {
              display: flex;
              align-content: center;
              align-items: center;
              font: @font_4;
              color: @acc_text_color;
            }

            > .team_l,
            > .team_r {
              h2 {
                overflow: hidden;
                width: 120px;
                white-space: nowrap;
                font: @font_4;
                margin: auto 14px;
              }
            }

            > .team_l {
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-end;
              text-align: right;
            }

            > .score {
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              margin: auto 20px;
              color: @color_w;
              text-shadow: 0 0 20px rgba(200, 0, 200, 1);
              flex: 1 1 auto;
            }

            > .team_r {
              flex-direction: row-reverse;
              flex-wrap: nowrap;
              justify-content: flex-end;

            }
          }

          .time {
            flex-basis: 200px;
            text-align: right;
          }

          &::after { // lvl 2 - arrow
            content: @fa-var-caret-down;
            position: absolute;
            right: 10px;
            top: 50%;
            .transform(translateY(-50%) scale(1));
            .fas;
            font-size: 20px;
            z-index: 2;
            color: @acc_arrow_n;
          }
        }

        > .acc_body {
          background: @acc_2color;
          padding: 8px 10px 10px;
          .radius(0 0 @br @br);
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            background: @acc_2color;
            position: absolute;
            z-index: 2;
            left: 0;
            top: -6px;
          }

        }

        &.active {

          > .acc_header {
            position: relative;

            &::after { // lvl 1 - arrow
              .transform(translateY(-50%) scale(-1));
              color: @acc_arrow_a;
            }

            &::before { // lvl 1 - cup
              color: @acc_arrow_a;
            }

            color: @acc_text_color;
          }
        }
      }


    }

    &.active {
      > .acc_header {
        &::after { // lvl 1 - arrow
          .transform(translateY(-50%) scale(-1));
          color: @color_1;
        }

        color: @color_b1;
      }
    }
  }

  img {
    max-width: 100%;
  }

}


// ACCORDION v4 <details>
details {
  summary {
    &::-webkit-details-marker {
      display: none;
    }

    &::-moz-list-bullet {
      list-style-type: none;
    }
  }

}

details[open] {
  > summary {
    &::after { // lvl 1 - arrow
      content: @fa-var-caret-down;
      position: absolute;
      right: 10px;
      top: 50%;
      .transform(translateY(-50%) scale(1));
      .fas;
      font-size: 20px;
      z-index: 2;
      color: @color_b5
    }
  }

  .content {
    animation-name: invisiblyGrowFontSize, fadeIn;
    animation-duration: 300ms, 200ms;
    animation-delay: 0ms, 300ms;
  }
}

details,
summary {
  outline: none !important;

  &:hover {
    cursor: pointer;
  }
}

.accordion_v4 {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  background: @color_b3;
  .shadow(@shadow);
  padding: 0 14px;
  .radius(@br);

  > details {

    > summary {
      .centery;
      text-align: left;
      padding: 10px 40px 10px 0;
      font: @font_4;
      color: @color_b5;
      text-decoration: none;
      .anim_on(@speed);
      min-height: 60px;
      background: none;

      &::after { // lvl 1 - arrow
        content: @fa-var-caret-down;
        position: absolute;
        right: 10px;
        top: 50%;
        .transform(translateY(-50%) scale(1));
        .fas;
        font-size: 20px;
        z-index: 2;
        color: @color_b5
      }

      &::before {
        content: @fa-var-trophy;
        .fas;
        color: @color_b4;
        margin-right: 10px;
      }
    }

    > .content {

      > details {
        &:not(:last-child) {
          margin-bottom: 3px;
        }

        &:last-child {
          margin-bottom: 11px;
        }

        > summary {
          .radius(@br);
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-content: stretch;
          align-items: center;

          padding: 0 40px 0 14px;
          font: @font_4;
          color: @color_b5;
          background: @color_b2;
          text-decoration: none;
          .anim_on(@speed);
          min-height: 40px;

          &::after { // lvl 1 - arrow
            content: @fa-var-caret-down;
            position: absolute;
            right: 10px;
            top: 50%;
            .transform(translateY(-50%) scale(1));
            .fas;
            font-size: 20px;
            z-index: 2;
            color: @color_b5
          }
        }

        > .content {
          background: @color_b2;
          padding: 10px 14px 14px;
          .radius(0 0 @br @br);

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            background: @color_b2;
            position: absolute;
            z-index: 2;
            left: 0;
            top: -6px;
          }


        }
      }
    }

  }


}