//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

// BIG HEADER
// ====================================
.header {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: relative;
  z-index: 999;
  transition: max-height 0.2s ease-out !important;

  > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(@color_w, 1);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0 10px 30px 0 rgba(@color_b1, .05);
  }

  &.header-fixed {
    top: 0;
    left: 0;
    max-height: none;
    position: fixed;

    .header-wrap {
      max-height: 50px;
    }

    &::after {
      opacity: 1;
      visibility: visible;
    }

    .header__logo {
      svg {
        height: 40px !important;
      }
    }

    & ~ .padding-dinamic-header {
      padding-bottom: 50px;
    }
  }

  > .header-wrap {
    width: 100%;
    display: flex;
    padding: 10px @gap;
    align-items: center;
    align-content: center;
    position: relative;

    .header__logo {
      display: flex;
      width: auto;
      height: auto;
      position: relative;

      &.logo-main-page {
        a {
          display: none;
        }
      }

      a {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
      }

      img {
        width: auto;
        height: 60px;

        @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
          height: 45px;
        }
      }

      svg {
        width: auto;
        height: 60px;
        shape-rendering: geometricPrecision;
        text-rendering: geometricPrecision;
        image-rendering: optimizeQuality;
        fill-rule: evenodd;
        clip-rule: evenodd;
        transition: height 0.2s ease-in-out;

        .logo-l-blue {
          fill: #0057B8;
          fill-rule: nonzero
        }

        .logo-d-blue {
          fill: #051A66;
          fill-rule: nonzero
        }

        .logo-red {
          fill: #CE1126;
          fill-rule: nonzero
        }

        .logo-yelow {
          fill: gold;
          fill-rule: nonzero
        }

        @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
          height: 45px;
        }

      }

    }

    .languages__block {
      flex: 1 1 auto;
      display: flex;
      height: @elements_h;
      position: relative;

      .languages__drop {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        background: rgba(@color_w, 0);
        .radius(@br);
        align-self: start;
        .anim_on(0.2s);

        &::after {
          content: @fa-var-angle-down;
          opacity: .5;
          .fas;
          position: absolute;
          top: 0;
          right: 4px;
          display: flex;
          width: 20px;
          height: @elements_h;
          align-items: center;
          justify-items: center;
          justify-content: center;
          transform-origin: center center;
          pointer-events: none;
        }

        a {
          z-index: 1;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          justify-items: center;
          flex: 0 0 @elements_h;
          padding: 0 24px 0 7px;
          text-decoration: none;
          color: @color_b1 - 20%;
          font-weight: @f_sbold;
          text-shadow: 0 0 4px rgba(@color_w, 1), 0 0 10px rgba(@color_w, 1);

          &:not(.active) {
            max-height: 0;
            .anim_on(0.2s);
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
          }

        }

        &.active {
          box-shadow: 0 5px 30px -5px rgba(@color_b1, .2);
          background: rgba(@color_w, .8);

          &::after {
            transform: scale(-1);
          }

          a:not(.active) {
            display: flex;
            max-height: none;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

  }
}

.btn-menu {
  margin-right: 20px;
  display: flex;
  align-items: center;
  align-content: center;

  > div {
    padding: 10px 0;
    width: 27px;
    height: @elements_h + 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &::before,
    span,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background: @color_4;
      transform-origin: 65% 50%;
    }
  }

  > span {
    margin-left: 10px;
    font: @font_4;
    font-weight: @f_bold;
  }

  &:hover {
    cursor: pointer;
  }
}

.btn-menu.active {
  > div {

    &::before {
      transform: rotate(-45deg);
      width: 70%;
    }

    span {
      display: none;
    }

    &::after {
      transform: rotate(45deg);
      width: 70%;
    }
  }
}

.main-menu {
  .anim_on(.25s);
  display: flex;
  //position: fixed !important;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(@color_b1, .5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  .btn-close-menu {
    position: absolute;
    left: 290px;
    color: @color_b4;
    top: 10px;
    z-index: 3;
    cursor: pointer;

    &::after {
      content: @fa-var-times;
      .fas;
      font-size: 20px;
    }
  }

  .main-menu__wrap {
    z-index: 2;
    .anim_on(.18s);
    display: flex;
    flex-direction: column;
    flex: 0 1 320px;
    background: @color_w;
    padding: 40px;
    transform: translateX(-100%);

    a {
      display: flex;
      width: 100%;
      height: @elements_h + 10px;
      text-decoration: none;
      color: @color_b1;
      align-content: center;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid @color_b6;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .main-menu__wrap {
      transform: translateX(0);
    }
  }
}

// SMALL HEADER
// ====================================


// MOBILE HEADRER
// ====================================


.header-fixed .notice-popup__covid {
  //transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  padding: 0 80px;
}


[class*='notice-popup_'] {

  &.notice-off{
    display: none;
  }

  display: flex;
  width: 100%;
  height: auto;
  max-height: 800px;
  background: @color_3;
  color: @color_w;
  z-index: 99;
  font: @font_8;
  //box-shadow: 0 10px 30px -5px rgba(@color_b1, .3);
  .anim_on(@speed);

  .close-notice-btn {
    display: none;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    align-content: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: @fa-var-times;
      .fas;
      color: @color_w;
    }
  }

  .notice__content {
    padding: 13px @gap;
    display: flex;
    max-height: 800px;
    overflow: hidden;
    .anim_on(@speed);
  }

  .btn-slide-notice {
    position: absolute;
    width: 100%;
    height: 36px;
    display: none;
    background: linear-gradient(0, rgba(@color_3, 1) 0%, rgba(@color_3, .98) 28%, rgba(@color_3, 0) 100%);
    left: 0;
    bottom: 0;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;

    > span {
      display: none;
      padding: 2px 10px;
      user-select: none;
      background: rgba(@color_w, .6);
      color: @color_3;
      border-radius: 6px 6px 0 0 ;
      .anim_on(@speed);

      &::after{
        .fas;
      }

      &:first-child::after{
        content: @fa-var-caret-down;
      }

      &:last-child::after{
        content: @fa-var-caret-up;
      }

      &:hover{
        cursor: pointer;
        background: rgba(@color_w, 1);
      }
    }

  }

  @media only screen and (max-width: 780px),
  only screen and (max-device-width: 780px) {
    font-size: 13px;

    &:not(.deployed){
      > .notice__content {
        padding: 10px 16px 0;
        max-height: 75px;
      }
      .btn-slide-notice {
        span:first-child{
          display: block;
        }
      }
    }
    &.deployed{
      > .notice__content {
        padding: 10px 16px 40px;
      }
      .btn-slide-notice {
        span:last-child{
          display: block;
        }
      }
    }
    .btn-slide-notice {
      display: flex;
    }

  }


}

[class*='_covid'] {
  //background: url("/img/gallery/covid_bg.jpg") 50% 50% / cover no-repeat;

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: -30px;
    left: -30px;
    width: 60px;
    height: 60px;
    background: url("/img/gallery/covid.png") 50% 50% / cover no-repeat;
  }
}






